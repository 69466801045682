import { defineStore } from "pinia"
import {
  TimelineGroupId,
  TimelineGroup,
  DroneModel,
  AnalyticsEvent,
  TimelineDroneUrlParams,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { TimelineChartType } from "@evercam/ui"
import moment from "moment-timezone"
import { useNuxtApp } from "#app"

const groupId = TimelineGroupId.Drone

interface TimelineDroneGroupState {
  showDrone: boolean
  droneFlights: DroneModel[]
}

export const useTimelineDroneGroupStore = defineStore({
  id: "timelineDroneGroup",
  state: (): TimelineDroneGroupState => ({
    showDrone: false,
    droneFlights: [],
  }),
  getters: {
    isDisabled(): boolean {
      return !this.droneFlights?.length
    },
    isVisible(): boolean {
      return this.showDrone && !this.isDisabled
    },
    group(): TimelineGroup {
      const groupConfig = {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
      }

      if (this.isDisabled) {
        return groupConfig as TimelineGroup
      }

      const droneMilestones = this.droneFlights.map((d) => {
        return {
          ...d,
          milestoneType: TimelineGroupId.Drone,
          color: TimelineColors.droneMilestone,
          timestamp: moment(d.date, "DD/MM/YYYY").toISOString(),
          text: `Drone flight: ${moment(d.date, "DD/MM/YYYY").format(
            "MMM Do YYYY"
          )}`,
          size: 28,
        }
      })

      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        timelineConfig: {
          label: "",
          height: 38,
          color: TimelineColors.droneMilestone,
          chartType: TimelineChartType.Milestones,
          events: droneMilestones,
        },
      }
    },
  },
  actions: {
    changeDroneFlightsVisibility(visibilityStatus) {
      if (this.showDrone === visibilityStatus) {
        return
      }
      this.showDrone = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.GroupsVisibilityDroneFlights,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineDroneUrlParams
  ) as (keyof TimelineDroneGroupState)[],
})
