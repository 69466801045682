import { defineStore } from "pinia"
import {
  TimelineGroupId,
  TimelineGroup,
  _360Scene,
  AnalyticsEvent,
  Timeline360UrlParams,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { TimelineChartType } from "@evercam/ui"
import moment from "moment-timezone"
import { useNuxtApp } from "#app"

const groupId = TimelineGroupId.ThreeSixty

interface Timeline360GroupState {
  show360: boolean
  _360WalksDates: _360Scene[]
}

export const useTimeline360GroupStore = defineStore({
  id: "timeline360Group",
  state: (): Timeline360GroupState => ({
    show360: false,
    _360WalksDates: [],
  }),
  getters: {
    isDisabled(): boolean {
      return !this._360WalksDates?.length
    },
    isVisible(): boolean {
      return this.show360 && !this.isDisabled
    },
    group(): TimelineGroup {
      const groupConfig = {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
      }

      if (this.isDisabled) {
        return groupConfig as TimelineGroup
      }

      const _360Milestones = this._360WalksDates.map((w) => {
        return {
          ...w,
          milestoneType: TimelineGroupId.ThreeSixty,
          color: TimelineColors._360Milestone,
          timestamp: moment(w.date, "DD-MM-YYYY").toISOString(),
          text: `360 Walk: ${moment(w.date, "DD-MM-YYYY").format(
            "MMM Do YYYY"
          )}`,
          size: 28,
        }
      })

      return {
        ...groupConfig,
        timelineConfig: {
          label: "",
          height: 38,
          color: TimelineColors._360Milestone,
          chartType: TimelineChartType.Milestones,
          events: _360Milestones,
        },
      }
    },
  },
  actions: {
    change360WalksVisibility(visibilityStatus) {
      if (this.show360 === visibilityStatus) {
        return
      }
      this.show360 = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.GroupsVisibility360Walks,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    Timeline360UrlParams
  ) as (keyof Timeline360GroupState)[],
})
